import request from '@/utils/request'
export const __getSwiperList = () => {
  return request({
    url: '/openApi/project/img/info/list',
    method: 'get',
    params: {
      project_name: 'th.gg首页',
      channel: 'th.gg.com',
      pageSize: 1000000000
    }

  })
}
