<template>
  <div class="FootSwiper">
    <van-swipe :show-indicators="false" :autoplay="3000">
      <van-swipe-item v-for="item in swipeList" :key="item.id">
        <img :src="item.src" alt="">
      </van-swipe-item>

    </van-swipe>
  </div>
</template>

<script>
const swiper1 = 'https://thcdn.gggamedownload.com/source/mobileAssets/-3.png'
const swiper2 = 'https://thcdn.gggamedownload.com/source/mobileAssets/-4.png'
const swiper3 = 'https://thcdn.gggamedownload.com/source/mobileAssets/-5.png'
export default {
  name: 'FootSwiper',
  data() {
    return {
      swipeList: [
        {
          id: 1,
          src: swiper1
        },
        {
          id: 2,
          src: swiper2
        },
        {
          id: 3,
          src: swiper3
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.FootSwiper {
  .van-swipe {
    img {
      width: 100%;
      vertical-align: middle;
    }
  }
}
</style>
