<template>
  <div class="List">
    <div v-if="list.length>0" class="ul">
      <div v-for="item in list" :key="item.id" class="li" @click="clickNews(item)">
        <div class="left">
          <div class="month">{{ item.createTime | fileter_monteh }}</div>
          <div class="year">{{ item.createTime | fileter_year }}</div>
        </div>
        <div class="right">
          <p class="title">
            <span class="title_type">{{ item.tag | fileter_type }}</span>
            <span class="title_text">{{ item.title }}</span>
          </p>
          <div class="content">
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>
    <van-empty v-else description="暂无数据了" />
  </div>
</template>

<script>
import { reqGetNewsList } from '@/api/news'

export default {
  name: 'List',
  filters: {
    fileter_monteh(val) {
      if (val) {
        const str = val.substring(0, 10)
        const index = str.indexOf('-')
        const newVal = str.substring(index + 1)
        return newVal
      }
      return val
    },
    fileter_year(val) {
      if (val) {
        const str = val.substring(0, 10)
        const index = str.indexOf('-')
        const newVal = str.substring(0, index)
        return newVal
      }
      return val
    },
    fileter_type(val) {
      switch (val) {
        case 'all':
          return '最新'
        case 'news':
          return '新闻'
        case 'announcement':
          return '公告'
        case 'gameWalkthrough':
          return '活动'
        case 'gameStrategy':
          return '见闻纪'
        default:
          return val
      }
    }
  },
  props: {
    tag: {
      required: true,
      type: null
    }
  },
  data() {
    return {
      activeIndex: 0,
      list: [],
      queryForm: {
        channel: 'th.gg.com',
        pageSize: 4,
        currentPage: 1,
        tag: this.$props.tag
      }
    }
  },
  created() {
    this.getNewsList()
  },
  methods: {
    async getNewsList() {
      const res = await reqGetNewsList(this.queryForm)
      this.list = res.data
    },
    gotoNewsList(e) {
      this.$router.push('/newsList')
    },
    clickNews({ id }) {
      const scrollTop = document.documentElement.scrollTop
      this.$router.push({
        path: '/newsDetails',
        query: {
          id,
          y: scrollTop
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {

}
.List {
  .ul {
    width: 100%;
          padding: 0 10px;
          padding-top: 16px;

        .li {
          width: 100%;
          display: flex;
          .left {
            width: 60px;
            min-width: 60px;
            border-right: 1px solid #d7d9da;
            padding-bottom: 33px;

            .month {
              font-size: 18px;
              color: #5C6371;
            }
            .year {
              font-size: 12px;
              color: #788FA1;
            }
          }
          .right {
            flex: 1;
            margin-left: 12px;
            overflow: hidden;
            .title {
              font-size: 13px;
              white-space: nowrap;
              overflow:hidden;
              text-overflow:ellipsis;
              .title_type {
                border-right: 1px solid #d49d77 ;
                color: #d49d77;
                padding: 0 5px;
              }
              .title_text {
                padding-left: 5px;
                color: #5B6475;
              }

            }
            .content {
              margin-top: 5px;
              font-size: 12px;
                text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              color: #898D95;

            }

          }
        }
        }
}
</style>
